// Brand colors
$primary:               #69726c !default;
$secondary:             #c3c9c6 !default;
$danger:                #df1c3a !default;
$light:                 #f8f9f0 !default;
$dark:                  #010101 !default;

$font-family-sans-serif:      'TT Commons', sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$enable-rounded: false !default;
$enable-negative-margins:     true !default;
$enable-cssgrid:              true !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
  9: $spacer * 9,
  10: $spacer * 12,
) !default;

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "3x5": calc(5 / 3 * 100%),
  "2x3": calc(3 / 2 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "10x16": calc(16 / 10 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

@import "node_modules/bootstrap/scss/bootstrap";

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 3s infinite;
   -moz-animation: flickerAnimation 3s infinite;
   -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
}

.swiper-pagination-bullet-active {
  background: var(--bs-primary, var(--bs-primary)) !important;
}

.swiper-button-next, .swiper-button-prev {
  color: var(--bs-dark, var(--bs-dark)) !important;
}

.form-control::placeholder, .form-select {
  color: var(--bs-primary) !important;
}

.massiveImage {
  background-image: url(/assets/img/cassias-bg.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-color: #DF1C3A;
}

.massiveImageCassias {
  background-image: url(/assets/img/cassias-bg.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-color: #DF1C3A;
}

.popover-header {
  background-color: #fff;
}

.rounded-20 {
  border-radius: 20px;
}


.custom-16x9 {
  width:100vw; height:calc(100vw*1.45);
}

@media (min-width: 992px) {
  .custom-16x9 {
    width:100vw; height:calc(100vw/1.77);
  }

  .massiveImage {
    height: calc(100vw/1.77);
    width: 170vw;
  }
}

.vjs-loading-spinner {
  display: none !important;
}